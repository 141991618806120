













































































import { Messages } from "@/models/enums/messages.enum";
import { ResponseDetailUserByAuth } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import { checkPasswordStrength } from "@/validator/globalvalidator";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
    newPassword: '' as string,
    userId: '' as string,
    isFormSubmitted: false,
      formChangePassword: this.$form.createForm(this, {
        name: "changePassword",
      }),
      formRulesChangePassword: {
        oldPassword: {
          label: "Current Password",
          name: "oldPassword",
          placeholder: "Insert your current password",
          decorator: [
            "oldPassword",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        newPassword: {
          label: "New Password",
          name: "newPassword",
          placeholder: "Insert your new password",
          decorator: [
            "newPassword",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmPassword: {
          label: "Confirm Password",
          name: "confirmPassword",
          placeholder: "Insert your confirm password",
          decorator: [
            "confirmPassword",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
                {
                  validator: this.customValidator,
                },
              ],
            },
          ],
        },
      },
    };
  },
  mounted () {
    this.getUserDetailByAuth()
  },
  methods:{
    submitForm (e: Event):void {
      e.preventDefault()
      this.formChangePassword.validateFields((err, values) => {
        if(err || this.isFormSubmitted) return;
        this.isFormSubmitted = true
        const payload = {
          userId: this.userId,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword
        }
        setTimeout(() => {
          userServices
            .changePassword(payload)
            .then((value) => {
              if(value) {
                this.$message.success(Messages.CHANGE_PASSWORD_SUCCESS)
                this.formChangePassword.resetFields()
              }
            })
            .catch(() => this.isFormSubmitted = false)
            .finally(() => this.isFormSubmitted = false)
        }, 500)
      })
    },
    customValidator (rule,value,callback) {
      if(this.newPassword !== value) {
        callback("Password doesn't match")
      } else {
        callback()
      }
    },
    getUserDetailByAuth () {
      userServices
        .userDetailAuth()
        .then((value:ResponseDetailUserByAuth) => {
          this.userId = value.id
        })
    }
  },
});
