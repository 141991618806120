var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    { attrs: { justify: "center", type: "flex" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-card",
                            {
                              staticStyle: { width: "35vw" },
                              attrs: { title: "Change Password" }
                            },
                            [
                              _c(
                                "a-form",
                                {
                                  attrs: {
                                    layout: "vertical",
                                    form: _vm.formChangePassword
                                  },
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.submitForm.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.formRulesChangePassword
                                            .oldPassword.label
                                      }
                                    },
                                    [
                                      _c("a-input-password", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRulesChangePassword
                                                .oldPassword.decorator,
                                            expression:
                                              "\n                      formRulesChangePassword.oldPassword.decorator\n                    "
                                          }
                                        ],
                                        attrs: {
                                          name:
                                            _vm.formRulesChangePassword
                                              .oldPassword.name,
                                          placeholder:
                                            _vm.formRulesChangePassword
                                              .oldPassword.placeholder,
                                          autocomplete: "false"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.formRulesChangePassword
                                            .newPassword.label
                                      }
                                    },
                                    [
                                      _c("a-input-password", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRulesChangePassword
                                                .newPassword.decorator,
                                            expression:
                                              "\n                      formRulesChangePassword.newPassword.decorator\n                    "
                                          }
                                        ],
                                        attrs: {
                                          name:
                                            _vm.formRulesChangePassword
                                              .newPassword.name,
                                          placeholder:
                                            _vm.formRulesChangePassword
                                              .newPassword.placeholder,
                                          autocomplete: "false"
                                        },
                                        model: {
                                          value: _vm.newPassword,
                                          callback: function($$v) {
                                            _vm.newPassword = $$v
                                          },
                                          expression: "newPassword"
                                        }
                                      }),
                                      _c(
                                        "ul",
                                        {
                                          staticStyle: { "font-size": "11px" }
                                        },
                                        [
                                          _c("li", [
                                            _vm._v(
                                              "contain at least one letter(a-z)"
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "contain at least one number(0-9)"
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "contain at least one letter(A-Z)"
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v("minumum 8 character")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label:
                                          _vm.formRulesChangePassword
                                            .confirmPassword.label
                                      }
                                    },
                                    [
                                      _c("a-input-password", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRulesChangePassword
                                                .confirmPassword.decorator,
                                            expression:
                                              "\n                      formRulesChangePassword.confirmPassword.decorator\n                    "
                                          }
                                        ],
                                        attrs: {
                                          name:
                                            _vm.formRulesChangePassword
                                              .confirmPassword.name,
                                          placeholder:
                                            _vm.formRulesChangePassword
                                              .confirmPassword.placeholder,
                                          autocomplete: "false"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-row",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          key: "submit",
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.isFormSubmitted,
                                            "html-type": "submit"
                                          },
                                          on: { click: _vm.submitForm }
                                        },
                                        [_vm._v(" Save ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }